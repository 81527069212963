import React, { useState } from 'react'
import Layout from '../components/Layout'
import ValidationSummary from '../components/validationSummary'
import TextInput from '../components/textInput'
import TextArea from '../components/textArea'
import EventPrices from '../components/event-prices'

const Joi = require('@hapi/joi')

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .label('Email')
    .required(),
  message: Joi.string().required(),
}).options({ stripUnknown: true })

function Home() {
  const [contact, setContact] = useState({
    email: '',
    phoneNumber: '',
    message: '',
  })
  const [validationErrors, setValidationErrors] = useState([])

  const [submitted, setSubmitted] = useState(false)
  const [messageSent, setMessageSent] = useState(false)

  function handleChange(e) {
    const { value } = e.target
    setContact({ ...contact, [e.target.name]: value })
  }

  async function sendEmail(e) {
    e.preventDefault()

    if (!submitted) {
      setSubmitted(true)
      const validationResult = schema.validate(contact)
      if (validationResult.error) {
        setValidationErrors(validationResult.error.details)
      } else {
        const res = await fetch(`/api/send-email`, {
          body: JSON.stringify(contact),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (res.status === 200) {
          setValidationErrors([])
          setSubmitted(false)
          setMessageSent(true)
        } else {
          const errors = await res.json()
          setValidationErrors(errors.details)
        }
      }
    }

    setSubmitted(false)
  }

  return (
    <Layout>
      <div className="section-1">
        <div className="section-shim">
          <div className="container">
            <div className="section">
              <div className="columns is-vcentered">
                <div className="column">
                  <div className="has-text-centered">
                    <p>
                      <strong>Speciality coffee</strong> on the streets of Calderdale!
                    </p>
                    <p>
                      <a href="/#events">Hire me</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-3" id="our-story">
        <div className="section-shim">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-7">
                  <h1 className="title">The Roady Story</h1>
                  <p>
                    My favourite things are serving great coffee (drinking it too obviously) and
                    chatting to people. Simple!
                  </p>
                  <p>
                    Realising this wasn&apos;t going to be so easy anymore with one way systems,
                    masks and indoor worries for people along with working from home being the new
                    normal, I decided to step out of the coffee shop and Roady was born.
                  </p>
                  <p>We can&apos;t wait to see you for a coffee and chat!</p>
                </div>
                <div className="column">
                  <img alt="The Roady coffee van" src="/van3.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-4" id="events">
        <div className="section-shim">
          <div className="container">
            <div className="section">
              <h1 className="title">Events and Hire</h1>
              <p>
                Being a mobile van we can come to you, so we are available to park up, warm up and
                serve you guys at your own events!
              </p>
              <EventPrices />
              <p>
                Also available for Weddings, Music Festivals, Food Festivals, Beer Festivals, Pub
                Beer Gardens, Corporate Events, Sporting Events, any sort of gathering where coffee
                is needed! Please contact for more info and prices
              </p>
            </div>
          </div>
        </div>
        <div className="section-5" id="contact-us">
          <div className="section-shim">
            <div className="container">
              <div className="section">
                <h1 className="title">Get In Touch!</h1>
                <p>
                  You can find us over on{' '}
                  <a href="https://www.instagram.com/roadycoffee/">Instagram</a> or{' '}
                  <a href="https://www.facebook.com/roadycoffee">Facebook</a>, or fill in the form
                  below to send us an email and we&apos;ll get back to you!
                </p>
                <ValidationSummary validationErrors={validationErrors} />
                {messageSent ? (
                  <div>
                    <strong>Thanks for getting in touch!</strong> We&apos;ll get back to you soon.
                  </div>
                ) : (
                  <form onSubmit={sendEmail}>
                    <div className="field">
                      <label htmlFor="email" className="label">
                        Email
                      </label>
                      <div className="control">
                        <TextInput
                          validationErrors={validationErrors}
                          value={contact.email}
                          onChange={handleChange}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="phoneNumber" className="label">
                        Phone Number
                      </label>
                      <div className="control">
                        <TextInput
                          validationErrors={validationErrors}
                          value={contact.phoneNumber}
                          onChange={handleChange}
                          name="phoneNumber"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="message" className="label">
                        Message
                      </label>
                      <div className="control">
                        <TextArea
                          validationErrors={validationErrors}
                          value={contact.message}
                          onChange={handleChange}
                          name="message"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <button
                          id="send-message"
                          type="submit"
                          className="button is-medium is-success"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .section-1 {
          background-color: #d01110;
          color: white;
        }
        .section-shim {
        }
        .section-1 p,
        .section-1 strong,
        .section-1 .title {
          color: white !important;
        }
        .section-1 h1 {
          font-size: 3em;
        }
        .section-1 p {
          font-size: 1.5em;
        }
        .section-1 .picture {
          background-color: white;
          border-radius: 50%;
          height: 350px;
          width: 350px;
          top: 5px;
          left: 5px;
          position: relative;
        }
        .section-1 a {
          color: white;
          text-decoration: underline;
        }
        .section-1 .picture-border {
          background-color: white;
          border-radius: 50%;
          height: 360px;
          width: 360px;
          margin: auto;
        }
        .section-2 {
          padding-bottom: 2em;
        }
        .section-3 {
          background-color: #ebe8e0;
          padding-bottom: 2em;
        }
        .section-4 {
          margin-bottom: 2em;
        }
        .section-5 {
          background-color: #ebe8e0;
          padding-bottom: 2em;
        }

        @media only screen and (max-width: 1023px) {
          .section-1 .picture {
            left: 0px;
          }
          .section-1 .column {
            text-align: center;
          }
        }
        @media only screen and (max-width: 600px) {
          .section-1 .picture {
            height: 250px;
            width: 250px;
          }
          .section-1 .picture-border {
            height: 260px;
            width: 260px;
          }
        }
      `}</style>
    </Layout>
  )
}

export default Home
