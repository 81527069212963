import React from 'react'

function EventPrices() {
  return (
    <div>
      <div className="columns">
        <div className="column">
          <div className="has-text-centered notification is-info is-light">
            <h1 className="subtitle">Treat your team</h1>
            <p>
              We supply the van and a trained barista to your workspace or school for up to an hour
              and a half.
            </p>
            <p>
              <strong>&pound;80 minumum spend</strong>
            </p>
          </div>
        </div>
      </div>
      <p>
        <strong>Prices include travel up to 10 miles from Halifax.</strong>
      </p>
    </div>
  )
}
export default EventPrices
